var Notification = {
    interval: 30000,
    badgeLocator: '#notificationBadge',
    poll: function() {
        setTimeout(function() {
            var curBadges = $(Notification.badgeLocator).text();
            var req = $.ajax({
                url: '/notification/getPoll',
                data: {curBadges: curBadges},
                dataType: 'json'
            });
            req.done(Notification.done);
            req.always(Notification.poll);
            req.fail(function(){});
        }, Notification.interval);
    },
    done: function(data) {
        $(Notification.badgeLocator).html(data.count);
    }
}

//Notification.poll();

$(document).ready(function() {
    $('#noticemsg-button').click(function(e){
        var noticemsg = $('#noticemsg');
        noticemsg.addClass('notification-container')
        $(this).append(noticemsg);
        noticemsg.show(0);
        noticemsg.find('.notification-close').click(function(e){
            e.preventDefault();
            e.stopPropagation();
            noticemsg.hide(0);
        });
        
        $('#messages .notifications').html('0');

        // Fire the "read all notifications" trigger
        // Ignore the response
        $.ajax('/notifications/read', {
            'method':'GET'
        });
    });
});