Rate = {
    'start': function(company,product) {
        this.companyId = company;
        this.productId = product;

        this.startStep1(this.companyId);

        if(!this.setupComplete) {
            // Set up add vendor/product processes
            this.rateSetup();
        }

        $('#vrModal').on('click', 'a.closeSteps', function(e){
            e.preventDefault();
            $('#theModal').foundation('reveal', 'close');
        });
    },
    'startProduct': function(category,company) {
        this.start();
        $('.addProduct').trigger('click');
        $('#productCategorySelect').val(category);
        $('#productVendorSelect').val(company);
    },
    'startCompany': function(category) {
        this.start();
        $('.addVendor').trigger('click');
    },
    'init': function() {
        this.setupComplete = false;
        this.dataObject = {};
        this.companyProductObject = {};
        this.token = CSRF();
        this.dataString = "_token="+this.token;
        this.vendorSelectHtml = null;

        this.companyId = null;
        this.productId = null;

        this.componentProductSelect = $('#componentProductSelect');
        this.componentVendorSelect = $('#componentVendorSelect');

        var self = this;

        // Impression events: scroll and tab click
        $('#vendorTabs .content').scroll(function() {
            clearTimeout($.data(this, 'scrollTimerWindow'));
            $.data(this, 'scrollTimerWindow', setTimeout(function() {
                impressions();
            }, 250));
        });

        $('#vendorTabs .tabs').on('toggled', function (event, tab) {
            impressions();
        });

        //
        // Step 0
        //
        this.componentVendorSelect.on('change', function(e){
            var value = $(this).val();
            if(value.length > 0){
                // Get products
                self.dataObject['id'] = value;

                var callback = function(d){
                    var html = '<option value="">Choose a product...</option>';
                    $.each(d, function(k,v){
                        html += '<option value="'+ v.id +'" data-id="'+ v.id +'">'+ v.name +'</option>'
                    });
                    self.componentProductSelect.html(html);
                    self.componentProductSelect.removeAttr('disabled');
                }

                self.makeJsonPost('/productsByCompany', callback);

            } else {
                self.componentProductSelect.attr('disabled', 'disabled');
                self.componentProductSelect.html('<option value="">Choose a product...</option>');
                $('.rateStep1Submit').addClass('disabled');
            }
        });

        this.componentProductSelect.on('change', function(e){
            var componentProductSelectVal = self.componentProductSelect.val();
            if(componentProductSelectVal.length > 0){
                $('.rateStep1ComponentSubmit').removeClass('disabled');
            } else {
                $('.rateStep1ComponentSubmit').addClass('disabled');
            }
        });

        //
        // Step 1
        //
        function resetComponentStep1Selects(){
            self.componentProductSelect.val('');
            self.componentVendorSelect.val('');
        }

        var productSelector = '.productSelect';
        var companySelector = '.vendorSelect';
        resetRatingStep1Selects();

        $(document).on('change', companySelector, function(e){
            var pSelect = $(productSelector);
            var vs = $(companySelector);

            var value = $(this).val();
            if(value.length > 0){
                // Get products
                self.dataObject['id'] = value;

                var callback = function(d){
                    var html = '<option value="">Choose a product...</option>';
                    $.each(d, function(k,v){
                        html += '<option value="'+ v.id +'" data-id="'+ v.id +'" data-slug="'+v.slug+'">'+ v.name +'</option>'
                    });
                    pSelect.html(html);
                    pSelect.removeAttr('disabled');

                    if(self.productId) {
                        // Update selection and submit form immediately
                        $(pSelect).find("option[data-id='"+self.productId+"']").prop('selected', true);
                        $(pSelect).trigger('change');
                        delete self.companyId;
                        delete self.productId;
                        $('#vrModal .rateStep1Submit').trigger('click');
                    }
                }

                self.makeJsonPost('/productsByCompany', callback);

            } else {
                pSelect.attr('disabled', 'disabled');
                pSelect.html('<option value="">Choose a product...</option>');
                $('.rateStep1Submit').addClass('disabled');
            }
        });

        $(document).on('change', productSelector, function(e){
            var ppSelectVal = $(this).val();
            if(ppSelectVal.length > 0){
                $('.rateStep1Submit').removeClass('disabled');
                $('.rate-vendor-start .rateStep1Submit').data('company-id', $('.vendorSelect').val());
                $('.rate-vendor-start .rateStep1Submit').data('product-id', ppSelectVal);
            } else {
                $('.rateStep1Submit').addClass('disabled');
            }
        });

        function resetRatingStep1Selects(){
            $('.productSelect').val('');
            $('.vendorSelect').val('');
        }

        $('.rateStep1ComponentSubmit').click(function(e){
            if(!$(this).hasClass('disabled')){
                $('#theModal').addClass('vrModal');
                App.loadModal('/submit-review', null, function() {
                    Rate.start($('#componentVendorSelect').val(),$('#componentProductSelect').val());
                });
                return;
            }

        });

        $(document).on('click', '#vrModal .rateStep1Submit', function(e){

            if(!$(this).hasClass('disabled')){
                e.preventDefault();
                self.addElementsToDataObject(['#vrModal .productSelect', '#vrModal .vendorSelect']);
                $('.vrModal-step1 .rateVendorStep1').removeClass('active');
                self.companyProductObject['company'] = $('#vrModal .vendorSelect option:selected').html();
                self.companyProductObject['product'] = $('#vrModal .productSelect option:selected').html();
                self.vendorSelectHtml = $('.vendorSelect').html();
                $('.theProduct').html(self.companyProductObject.product);
                $('.theCompany').html(self.companyProductObject.company);
                $('.rateVendorStep3 .next-nav a').attr('href','/'+$('#vrModal .vendorSelect option:selected').attr('data-slug')+'/'+$('#vrModal .productSelect option:selected').attr('data-slug'));
                self.setHeading('rateVendor');
                self.startStep2();
                resetRatingStep1Selects();
            }

        });

        //
        // Step 2
        //
        var cons = $('#avCons');
        var pros = $('#avPros');
        var summary = $('#avSummary');
        var phone = $('#avPhone');

        this.dataObject['starRating'] = '0';

        function checkStep2Fields()
        {
            if(typeof self.dataObject['recommend'] != 'undefined' && self.dataObject['starRating'] > 0) {
                $('.rateStep2-1Submit').removeClass('disabled');
            }
        }

        $(document).on('click', "#vrModal .star-ratings-sprite", function(e){
            var perc = e.offsetX/ $(this).width() * 100;

            var sr = $('.starRating');
            var starText = [
                "Serious problems",
                "Needs improvement",
                "Pretty good product",
                "Great product",
                "Absolutely PERFECT!"
            ];

            var width = '';

            switch (true){
                case(perc < 20):
                    width = '20%';
                    Rate.dataObject['starRating'] = '1';
                    break;
                case(perc >= 20 && perc < 40):
                    width = '40%';
                    Rate.dataObject['starRating'] = '2';
                    break;
                case(perc >=40  && perc < 60):
                    width = '60%';
                    Rate.dataObject['starRating'] = '3';
                    break;
                case(perc >=60  && perc < 80):
                    width = '80%';
                    Rate.dataObject['starRating'] = '4';
                    break;
                case(perc >=80):
                    width = '100%';
                    Rate.dataObject['starRating'] = '5';
                    break;
            }

            sr.css('width', width);

            $('#step2RateProductStarText').html(starText[self.dataObject['starRating']-1]).show();

            checkStep2Fields();
        });

        function resetRatingStep2Fields(){
            $('.ratingPage2TextField.minMaxDisplay').val('');
            $('.starRating').css('width', '0%');
            self.dataObject['starRating'] = '0';
            MinMaxDisplay();
        }

        checkStep22 = function(e) {
            var cons = $('.rateVendorStep2 #avCons');
            var pros = $('.rateVendorStep2 #avPros');
            var summary = $('.rateVendorStep2 #avSummary');
            var phone = $('.rateVendorStep2 #avPhone');
            var cons_min = cons.hasClass('minMaxDisplay') ? parseInt(cons.attr('data-minlength')) : 0;
            var pros_min = pros.hasClass('minMaxDisplay') ? parseInt(pros.attr('data-minlength')) : 0;
            var summary_min = summary.hasClass('minMaxDisplay') ? parseInt(summary.attr('data-minlength')) : 0;
            var phone_num = phone.val().replace(/\D/g,'');
            if((cons_min && cons.val().length < cons_min) || (pros_min && pros.val().length < pros_min) || (summary_min && summary.val().length < summary_min) || phone_num.length < 10){
                $('.rateStep2Submit').addClass('disabled').html('Complete Required Fields to Continue');
            } else{
                $('.rateStep2Submit').removeClass('disabled').html('Submit Your Review <i class="material-icons">chevron_right</i>');
            }
        };
        $(document).on('keyup change blur', '#vrModal .ratingPage2TextField', checkStep22);

        $(document).on('click', '#vrModal .rateBackToStep1', function(e){
            e.preventDefault();
            $('.rateVendorStep2').removeClass('active');
            $('.rateVendorStep1').addClass('active');
            $('.rateStep1Submit').addClass('disabled');
            this.pSelect.attr('disabled', 'disabled');
        });

        $(document).on('click', '#vrModal .rateBackToStep2-1', function(e){
            e.preventDefault();
            $('.rateVendorStep2-2').hide();
            $('.rateVendorStep2-1').show();
            $('#vrModal .vrModal-step2 .step-instructions').removeClass('column-one-half').removeClass('column-left');
        });

        this.dataObject['recommend'] = 1;

        $(document).on('click', '#vrModal .recommendButton', function(e){
            self.dataObject['recommend'] = 1;
            $('.rateVendorStep2 #avPros').attr('data-minlength','30');
            $('.rateVendorStep2 #avCons').attr('data-minlength','0');
            $('.addProductStep3 #avPros').attr('data-minlength','30');
            $('.addProductStep3 #avCons').attr('data-minlength','0');
            MinMaxDisplay();
            checkStep2Fields();
        });

        $(document).on('click', '#vrModal .notRecommendButton', function(e){
            self.dataObject['recommend'] = 0;
            $('.rateVendorStep2 #avPros').attr('data-minlength','0');
            $('.rateVendorStep2 #avCons').attr('data-minlength','30');
            $('.addProductStep3 #avPros').attr('data-minlength','0');
            $('.addProductStep3 #avCons').attr('data-minlength','30');
            MinMaxDisplay();
            checkStep2Fields();
        });

        $(document).on('click', '#vrModal .rateStep2-1Submit', function(e) {

            if (!$(this).hasClass('disabled')) {
                e.preventDefault();

                $('.rateVendorStep2-1').hide();
                $('.rateVendorStep2-2').show();

                $('#vrModal .vrModal-step2 .step-instructions').addClass('column-one-half').addClass('column-left');
            }
        });

        $(document).on('click', '#vrModal .rateStep2Submit', function(e){
            if(!$(this).hasClass('disabled')){

                e.preventDefault();

                var failSpan = $('.step2RateProductAjaxError');

                self.addElementsToDataObject(['#avSummary', '#avPros', '#avCons', '#avPhone']);

                // Populate the vendor ratings widget
                var callback = function(){
                    $('#vendorSelect2').html(self.vendorSelectHtml);
                    self.setHeading('rateVendor');
                    self.startStep3();
                    resetRatingStep2Fields();

                };

                var fail = function(jqXHR){

                    var json = jqXHR.responseJSON;
                    failSpan.html(json.data.message);
                    failSpan.show();

                    $('.rateStep2Submit').addClass('disabled');
                };

                self.makeJsonPost('/submitRateVendor', callback, fail);
            }

        });

        //
        // Step 3
        //
        var ps2Selector = '#productSelect2';
        var vs2Selector = '#vendorSelect2';

        function resetRatingStep3Fields(){
            $(ps2Selector).val('');
            $(vs2Selector).val('');
        }

        $(document).on('change', vs2Selector, function(e){
            var vs2 = $('#vendorSelect2');
            var ps2 = $('#productSelect2');
            var value = $(this).val();
            if(value.length > 0){

                self.dataObject['id'] = value;

                var callback = function(d){
                    var html = '<option value="">Choose a product...</option>';
                    $.each(d, function(k,v){
                        html += '<option value="'+ v.id +'" data-id="'+ v.id +'">'+ v.name +'</option>'
                    });
                    ps2.html(html);
                    ps2.removeAttr('disabled');
                };

                self.makeJsonPost('/productsByCompany', callback);

            } else {
                ps2.attr('disabled', 'disabled');
                ps2.html('<option value="">Choose a product...</option>');
                $('.ratingStep3Submit').addClass('disabled');
            }
        });

        $(document).on('change', ps2Selector, function(e){
            var ps2 = $('#productSelect2');
            var ps2Val = ps2.val();
            if(ps2Val.length > 0){
                $('.ratingStep3Submit').removeClass('disabled');
            } else {
                $('.ratingStep3Submit').addClass('disabled');
            }
        });

        $(document).on('click', '#vrModal .ratingStep3Submit', function(e){
            if(!$(this).hasClass('disabled')){
                e.preventDefault();
                var vs2 = $('#vendorSelect2');
                var ps2 = $('#productSelect2');
                $('.rateVendorStep3').removeClass('active');
                self.companyProductObject['company'] = $('#vendorSelect2 option:selected').html();
                self.companyProductObject['product'] = $('#productSelect2 option:selected').html();

                $('.theProduct').html(self.companyProductObject.product);
                $('.theCompany').html(self.companyProductObject.company);

                self.addElementsToDataObject(['#productSelect2', '#vendorSelect2']);
                self.dataObject['productSelect'] = ps2.val();
                self.dataObject['vendorSelect'] = vs2.val();
                self.setHeading('rateVendor');
                resetRatingStep3Fields();
                $('.rateVendorStep2').addClass('active');
                $('.rateVendorStep2-2').hide();
                $('.rateVendorStep2-1').show();
                $('#vrModal .vrModal-step2 .step-instructions').removeClass('column-one-half').removeClass('column-left');
            }
        });
    },
    'startStep1': function(company) {
        this.makeAllStepsInactive();
        $('.rateVendorStep1').addClass('active');

        // Clear out the current company and product just in case
        this.companyProductObject['company'] = '';
        this.companyProductObject['product'] = '';
        $('.theProduct').html();
        $('.theCompany').html();

        this.setHeading('rateVendor');

        // Set the selected company, if provided
        var select = $('.vendorSelect').find("option[data-id='"+company+"']");
        select.prop('selected', true);
        select.parent().trigger('change');
    },
    'startStep2': function(product) {
        $('#vrModal .rateVendorStep1').removeClass('active');
        $('.rateVendorStep2').addClass('active');
        $('.rateVendorStep2-2').hide();

        self.productId = null;
        self.companyId = null;
    },
    'startStep3': function(product) {
        $('.rateVendorStep2').removeClass('active');
        $('.rateVendorStep3').addClass('active');
    },
    // Utility functions
    'makeAllStepsInactive': function() {
        $('#vrModal .rate-vendor-steps').removeClass('active');
    },
    'addElementsToDataObject': function(theArray){
        var self = this;
        $.each(theArray, function(k, v){
            var name = v.replace('.', '');
            name = name.replace('#', '');
            name = name.split(' ').pop();
            self.dataObject[name] = $(v).val();
        });
    },
    'makeJsonPost': function(endpoint, callback, fail){
        var theData = this.dataString;

        $.each(this.dataObject, function(k, v){
            theData += '&' + k + '=' + v;
        });

        $.post(endpoint, theData, function(d){
            if(typeof callback !== 'undefined'){
                callback(d);
            }
        }, 'json')
        .fail(function(jqXHR){
            if(typeof fail !== 'undefined'){
                fail(jqXHR);
            }
        });
    },
    'setHeading': function(type){  // type = 'addVendor', 'rateVendor', or 'addProduct'
        $('.modalHeading').removeClass('active');
        switch(type){
            case 'addVendor':
                $('.addVendorHeading').addClass('active');
                break;
            case 'addProduct':
                $('.addProductHeading').addClass('active');
                break;
            case 'rateVendor':
                $('.rateVendorHeading').addClass('active');
                break;
        }
    },
    'rateSetup': function() {
        // Add Vendor code
        $('.addVendor').click(function(e){
            e.preventDefault();

            Rate.makeAllStepsInactive();
            $('.addVendorStep1').addClass('active');
            Rate.setHeading('addVendor');
        });

        function clearInputsAddVendorStep1(){
            $('.addVendorInput').val('');
        }

        // Add Vendor Step 1
        var vName = $('#nameOfVendor');
        var vDesc = $('#vendorDescription')
        var addStep1Submit = $('.addVendorStep1Submit');

        // Clear out the current company and product just in case
        Rate.companyProductObject['company'] = '';
        Rate.companyProductObject['product'] = '';
        $('.theProduct').html();
        $('.theCompany').html();

        var resetAddVendorStep1Fields = function(){
            $('.addVendorInput').val('');
        }

        $('.addVendorInput').on('keyup', function(){
            if(vName.val().length > 0 && vDesc.val().length > 0){
                addStep1Submit.removeClass('disabled');
            } else {
                addStep1Submit.addClass('disabled');
            }
        });

        addStep1Submit.click(function(e){
            if(!$(this).hasClass('disabled')){

                e.preventDefault();

                addStep1Submit.removeClass('disabled');

                Rate.addElementsToDataObject(['#nameOfVendor', '#vendorDescription']);

                var callback = function(d){

                    Rate.dataObject['newVendorId'] = d.data.companyId;
                    Rate.dataObject['newVendorLink'] = d.data.companyLink;

                    Rate.companyProductObject['company'] = $('#nameOfVendor').val();
                    $('.theCompany').html(Rate.companyProductObject.company);
                    $('.theVendor').html(Rate.companyProductObject['company']);
                    $('#newVendorLink').attr('href', Rate.dataObject['newVendorLink']);

                    // Go to the next tab
                    $('.addVendorStep1').removeClass('active');
                    Rate.setHeading('addVendor');
                    $('.addVendorStep2').addClass('active');

                    resetAddVendorStep1Fields();
                }

                var fail = function(jqXHR){

                    var failSpan = $('.step1AboutVendorAjaxError');
                    var json = jqXHR.responseJSON;

                    failSpan.html(json.data.message);
                    failSpan.show();

                    addStep1Submit.addClass('disabled');
                }
                Rate.makeJsonPost('/submitAddVendor', callback, fail);

            }

        });

        // Add Vendor Step 2
        var product = $('#nameOfProduct');
        var addVendorStep2Submit = $('.addVendorStep2Submit');

        resetAddVendorStep2Fields = function(){
            product.val('');
        }

        product.on('keyup', function(){
            if(product.val().length > 0){
                addVendorStep2Submit.removeClass('disabled');
            } else {
                addVendorStep2Submit.addClass('disabled');
            }
        });

        addVendorStep2Submit.click(function(e){
            if(!$(this).hasClass('disabled')){

                e.preventDefault();
                Rate.addElementsToDataObject(['#nameOfProduct']);

                var callback = function(d){

                    Rate.companyProductObject['product'] = $('#nameOfProduct').val();
                    $('.theProduct').html(Rate.companyProductObject.product);

                    $('.addVendorStep2').removeClass('active');
                    Rate.setHeading('addVendor');
                    $('.addVendorStep3').addClass('active');
                    resetAddVendorStep2Fields();
                }

                Rate.makeJsonPost('/checkVendorName', callback);
            }

        });


        // Add Vendor Step 3
        var pd = $('#productDescription');
        var cs = $('#categorySelect');
        var addVendorStep3Submit = $('.addVendorStep3Submit');
        var addVendorBackToStep2 = $('.addVendorBackToStep2');
        var failSpan = $('.step3ProductDetailsAjaxError');

        var resetAddVendorStep3Inputs = function(){
            pd.val('');
            cd.val('');
        }

        $('#categorySelect').on('change', function(e){
            changeStep3ProductDetailsInput();
        });

        pd.on('keyup', function(e){
            changeStep3ProductDetailsInput();
        });

        function changeStep3ProductDetailsInput(){

            var pdVal = pd.val();
            var csVal = cs.val();

            if(pdVal.length > 0 && csVal.length > 0){
                addVendorStep3Submit.removeClass('disabled');
            } else{
                addVendorStep3Submit.addClass('disabled');
            }
        }

        addVendorStep3Submit.click(function(e){
            if(!$(this).hasClass('disabled')){

                e.preventDefault();
                var failSpan = $('.step3ProductDetailsAjaxError');
                failSpan.hide();

                Rate.addElementsToDataObject(['#productDescription', '#categorySelect']);

                var callback = function(d){

                    Rate.dataObject['productSelect'] = d.data.productId;
                    $('.addVendorStep3').removeClass('active');
                    Rate.setHeading('addProduct');
                    $('.addProductStep2').addClass('active');

                    resetAddVendorStep3Inputs();
                }

                var fail = function(jqXHR){

                    var json = jqXHR.responseJSON;
                    failSpan.html(json.data.message);
                    failSpan.show();

                    addVendorStep3Submit.addClass('disabled');
                }

                Rate.makeJsonPost('/submitAddProduct', callback, fail);
            }

        });

        addVendorBackToStep2.click(function(e){
            e.preventDefault();

            $('.addVendorStep3').removeClass('active');
            Rate.setHeading('addVendor');
            $('.addVendorStep2').addClass('active');

        });

        // Add Product code

        $('.addProduct').click(function(e){
            e.preventDefault();

            Rate.makeAllStepsInactive();
            Rate.setHeading('addProduct');

            $('.addProductStep1').addClass('active');

        });

        var pvs = $('#productVendorSelect');
        var pcs = $('#productCategorySelect');
        var pn = $('#theProductName');
        var tpd = $('#theProductDescription');

        var productStep1Submit = $('.productStep1Submit');
        var failSpan = $('.step1AboutProductAjaxError');


        $('.step1AboutProductSelect').on('change', function(e){
            changeStep1AboutProductInput();
        });

        $('.step1AboutProductInput').on('keyup', function(e){
            changeStep1AboutProductInput();
        });

        // Add Product Step 1

        var resetAddProductStep1Inputs = function(){
            pvs.val('');
            pcs.val('');
            pn.val('');
            tpd.val('');
        }

        function changeStep1AboutProductInput(){
            var pvsVal = pvs.val();
            var pcsVal = pcs.val();
            var pnVal = pn.val();
            var tpdVal = tpd.val();

            if(pvsVal.length > 0 && pnVal.length > 0){
                productStep1Submit.removeClass('disabled');
            } else{
                productStep1Submit.addClass('disabled');
            }
        }
        var save_q = '000';
        var prods_obj = {options:[], res_index:0};
        $('#theProductName').on('keyup', function(e) {
            var kcode = e.keyCode || e.which;
            var pn = $(this);
            var val = pn.val();
            //console.log(kcode);
            if(kcode != 8 && kcode != 13 && kcode != 16 && kcode != 17 && !(kcode >= 35 && kcode <= 40) && kcode!= 46) {
                //console.log(val.length, val.indexOf(save_q), val.indexOf(save_q) == -1);
                if (val.length && val.indexOf(save_q) !== 0) {
                    save_q = val;
                    $.ajax({
                        url: '/search/products',
                        method: 'GET',
                        data: 'query=' + encodeURI(val),// + (pvs.val().length ? '&parent_id=' + pvs.val() : ''),
                        dataType: 'json'
                    }).done(function (d) {
                        prods_obj.options = d.data.response;
                        prods_obj.res_index = 0;
                        autoComplete(pn, prods_obj, -1);
                    }).fail(function (d) {
                        //console.log('fail', d.data.message);
                    });
                } else if (prods_obj.options.length) {
                    autoComplete(pn, prods_obj, -1);
                }
            } else if(prods_obj.options.length && (kcode == 38 || kcode == 40)) {
                if(kcode == 38 && prods_obj.res_index > 0) prods_obj.res_index--;
                if(kcode == 40 && prods_obj.res_index < prods_obj.options.length-1) prods_obj.res_index++;
                autoComplete(pn, prods_obj, prods_obj.res_index);
            }
        });
        function autoComplete(obj, obj_vals, override) {
            var s = obj.val().length;
            var options = obj_vals.options;
            if (override >= 0) {
                obj.val(options[override]['value']);
                //obj.selectRange(s, options[override]['name'].length);
                obj_vals.res_index = override;
                return;
            }
            obj_vals.res_index = 0;
            for (var i in options) {
                if (options[i]['value'].toLowerCase().indexOf(obj.val().toLowerCase()) == 0) {
                    obj.val(options[i]['value']);
                    obj.selectRange(s, options[i]['value'].length);
                    break;
                }
            }
        }
        productStep1Submit.click(function(e){
            if(!$(this).hasClass('disabled')){
                e.preventDefault();
                var failSpan = $('.step1AboutProductAjaxError');
                failSpan.hide();
                if(prods_obj.options.length) {
                    for (var i in prods_obj.options) {
                        if(prods_obj.options[i]['value'] == pn.val()) {
                            if(prods_obj.options[i]['parent_id'] != pvs.val()) {
                                failSpan.html('That product name is already in use by '+pvs.find('option[value="'+prods_obj.options[i]['parent_id']+'"]').text()+'.');
                                failSpan.show();
                            } else {
                                $('.addProductStep1').removeClass('active');
                                $('.rateVendorStep1').addClass('active');
                                $('.rateStep1Submit').removeClass('disabled');
                                $('.vendorSelect').val($('.step1AboutProductSelect').val());
                                $('.productSelect').delay(500).val(prods_obj.options[i]['id']);
                                pn.val('');
                            }
                            return;
                        }
                    }
                }
                Rate.addElementsToDataObject(['#nameOfProduct']);
                Rate.companyProductObject['product'] = $('#theProductName').val();
                $('.theProduct').html(Rate.companyProductObject.product);

                $('.addProductStep1').removeClass('active');
                $('.addVendorStep3').addClass('active');
                resetAddVendorStep2Fields();

                // These names are important. Posting to create a new product is done in two places. The post vars need to be the same.
                Rate.dataObject['productDescription'] = tpd.val();
                Rate.dataObject['nameOfProduct'] = pn.val();
                Rate.dataObject['newVendorId'] = pvs.val();
                Rate.dataObject['categorySelect'] = pcs.val();
                Rate.dataObject['vendorName'] = $('#productVendorSelect option:selected').text();
                $('.theCompany').html(Rate.dataObject['vendorName']);

                var callback = function(d){

                    Rate.dataObject['productSelect'] = d.data.productId;

                    Rate.companyProductObject['product'] = Rate.dataObject['nameOfProduct'];
                    Rate.companyProductObject['company'] = Rate.dataObject['vendorName'];

                    $('.theProduct').html(Rate.companyProductObject.product);
                    $('.theCompany').html(Rate.companyProductObject.company);

                    $('.addProductStep1').removeClass('active');
                    Rate.setHeading('addProduct');
                    $('.addProductStep2').addClass('active');

                    resetAddProductStep1Inputs();
                }

                var fail = function(jqXHR){

                    var json = jqXHR.responseJSON;
                    failSpan.html(json.data.message);
                    failSpan.show();

                    //productStep1Submit.addClass('disabled');
                }

                Rate.makeJsonPost('/submitAddProduct', callback, fail);
            }

        });
        // Add Product Step 2
        var sr = $('.starRating');
        var productStep2Submit = $('.productStep2Submit');
        var productBackToStep2 = $('.productBackToStep2');


        var resetAddProductStep2Inputs = function(){
            sr.css('width', '0%');
            Rate.dataObject['starRating'] = '0';
            delete(Rate.dataObject['recommend']);
            $('#vrModal .recommendButton').is(':checked', false);
            $('#vrModal .notRecommendButton').is(':checked', false);
        }

        productStep2Submit.click(function(e){
            if(!$(this).hasClass('disabled')){

                $('.addProductStep2').removeClass('active');
                Rate.setHeading('addProduct');
                $('.addProductStep3').addClass('active');
            }

        });

        $('.vendorBackToStep1').click(function(e){
            $('.addVendorStep3').removeClass('active');
            Rate.setHeading('addProduct');
            $('.addProductStep1').addClass('active');
        });
        productBackToStep2.click(function(e){
            $('.addProductStep3').removeClass('active');
            Rate.setHeading('addProduct');
            $('.addProductStep2').addClass('active');
        });

        // Add Product Step 3

        var failSpan = $('.step1RateTheProductAjaxError');

        var ps = $('.addProductStep3 #avSummary');
        var pp = $('.addProductStep3 #avPros');
        var pc = $('.addProductStep3 #avCons');
        var ph = $('.addProductStep3 #avPhone')

        var resetAddProductStep3Inputs = function(){
            ps.val('');
            pp.val('');
            pc.val('');
            MinMaxDisplay();
        }

        var productStep3Submit = $('.productStep3Submit');

        $('.productStep3TextField').on('change keyup blur', function(){
            var cons_min = pc.hasClass('minMaxDisplay') ? parseInt(pc.attr('data-minlength')) : 0;
            var pros_min = pp.hasClass('minMaxDisplay') ? parseInt(pp.attr('data-minlength')) : 0;
            var summary_min = ps.hasClass('minMaxDisplay') ? parseInt(ps.attr('data-minlength')) : 0;
            var phone_num = ph.val().replace(/\D/g,'');
            if((cons_min && pc.val().length < cons_min) || (pros_min && pp.val().length < pros_min) || (summary_min && ps.val().length < summary_min) || phone_num.length < 10){
                productStep3Submit.addClass('disabled').html('Complete Required Fields to Continue');
            } else{
                productStep3Submit.removeClass('disabled').html('Submit Your Review <i class="material-icons">chevron_right</i>');
            }
        });

        productStep3Submit.click(function(e){

            if(!$(this).hasClass('disabled')){

                e.preventDefault();

                Rate.dataObject['avSummary'] = ps.val();
                Rate.dataObject['avPros'] = pp.val();
                Rate.dataObject['avCons'] = pc.val();
                Rate.dataObject['avPhone'] = ph.val();

                var callback = function(d){

                    $('.addProductStep3').removeClass('active');
                    Rate.setHeading('addProduct');

                    // Step 2 and three stuff go together. Reset step 2 stuff after adding the rating
                    // because we need the star percentage from step 2 to submit in step 3.
                    resetAddProductStep2Inputs();
                    resetAddProductStep3Inputs();

                    Rate.companyProductObject['company'] = '';
                    Rate.companyProductObject['product'] = '';
                    $('.theProduct').html();
                    $('.theCompany').html();

                    $('.rateVendorStep3').addClass('active');

                }

                var fail = function(jqXHR){

                    var json = jqXHR.responseJSON;
                    failSpan.html(json.data.message);
                    failSpan.show();

                    productStep3Submit.addClass('disabled').html('Complete Required Fields to Continue');
                }

                Rate.makeJsonPost('/submitRateVendor', callback, fail);
            }

        });

        var companyReversedArray = [];

        var firstTab = $('#vendorTabs > ul > li:first');
        firstTab.addClass('active');
        var activeTab = $('#vendorTabs > ul > li.active > a');
        var activeHref = activeTab.attr('href');
        $(activeHref).addClass('active');

        $('#vendorTabs').on('click', '.tab-title', function(e){
            e.preventDefault();
            var href = $(this).children('a').attr('href');
            $(this).nearest('#vendorTabs').find('.tabs-content > .content').removeClass('active');
            $(href).addClass('active');
        });

        $('#byName').children('li').first().addClass('active');

    }
};

Rate.init();