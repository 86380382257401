/**
 * Perform moderation actions:
 * - Flag content as Spam
 * - Report content
 * - Report user
 * - Block content
 * - Block user
 */
$(document).ready(function () {
    var current_actions = {}; // Active flag object to prevent multiple requests until initial request is complete.
    function doModerationAction(action, id, method, data) {
        var url = '/moderation/' + action;
        data.push({'name':'id','value':id});
        data.push({'name':'_token','value':$("meta[name=_token]").attr('content')});
        if (!App.User.LoggedIn) {
            $('.login_register').first().trigger('click');
            return;
        }

        var callbacks = {
            'default':function(id, action) {
                return function(d) {
                    delete current_actions[action];
                    var m = $('#theModal');
                    m.html("<h1 class='blue-header'>Thank You!</h1><p>" + d.data.message + "</p><a class='close-reveal-modal'>&times;</a>");
                    m.foundation('reveal', 'open');
                };
            },
            'block-content':function(id, action){
                return function(d) {
                    delete current_actions[action];
                    $('[data-content-id="' + id + '"]').delay(1000).hide('slow');
                    
                    var m = $('#theModal');
                    m.html("<h1 class='blue-header'>Success!</h1><p>" + d.data.message + "</p><a class='close-reveal-modal'>&times;</a>");
                    m.foundation('reveal', 'open');
                }
            },
            'approve-content':function(id, action){
                return function(d) {
                    delete current_actions[action];

                    var m = $('#theModal');
                    m.html("<h1 class='blue-header'>Success!</h1><p>" + d.data.message + "</p><a class='close-reveal-modal'>&times;</a>");
                    m.foundation('reveal', 'open');
                }
            },
            'flag-like':function(id, action){
                return function(d) {
                    delete current_actions[action];
                    $('[data-content-id="' + id + '"].interact-icons').replaceWith(d.data.html);
                }
            },
            'flag-dislike':function(id, action){
                return function(d) {
                    delete current_actions[action];
                    $('[data-content-id="' + id + '"].interact-icons').replaceWith(d.data.html);
                }
            },
            'block-user':function(id, action){
                return function(d) {
                    delete current_actions[action];
                    var m = $('#theModal');
                    m.html("<h1 class='blue-header'>Success!</h1><p>" + d.data.message + "</p><a class='close-reveal-modal'>&times;</a>");
                    m.foundation('reveal', 'open');
                }
            },
            'unblock-user':function(id, action){
                return function(d) {
                    delete current_actions[action];
                    var m = $('#theModal');
                    m.html("<h1 class='blue-header'>Success!</h1><p>" + d.data.message + "</p><a class='close-reveal-modal'>&times;</a>");
                    m.foundation('reveal', 'open');
                }
            }
        };

        var callback = callbacks['default'];
        if(callbacks.hasOwnProperty(action)) {
            //console.log('found callback for '+action);
            callback = callbacks[action];
        }
        if(current_actions[action] == undefined){
            current_actions[action] = 1;
            //console.log('initiating ' + action);
            if (method == 'POST') {
                $.ajax({
                    url: url,
                    method: method,
                    data: data,
                    dataType: 'json'
                }).done(callback(id, action)).fail(function (d) {
                    var m = $('#theModal');
                    m.html("<h1 class='blue-header'>Sorry, an error occurred</h1><p>" + d.responseJSON.data.message + "</p><a class='close-reveal-modal'>&times;</a>");
                    m.foundation('reveal', 'open');
                    delete current_actions[action];
                });
            } else {
                $.ajax({
                    url: url,
                    method: method,
                    data: data,
                    dataType: 'html'
                }).done(function (d) {
                    $('#theModal').html(d).foundation('reveal', 'open');
                    $('#theModal').find('form').submit(function (e) {
                        e.preventDefault();
                        var method = ($(this).data('method')) ? $(this).data('method') : 'POST';
                        var data = $(this).serializeArray();
                        doModerationAction($(this).data('action'), $(this).data('id'), method, data);
                        delete current_actions[action];
                    });
                }).fail(function (d) {
                    d = $.parseJSON(d);
                    var m = $('#theModal');
                    m.html("<h1 class='blue-header'>Sorry, an error occurred</h1><p>" + d.responseJSON.data.message + "</p><a class='close-reveal-modal'>&times;</a>");
                    m.foundation('reveal', 'open');
                    delete current_actions[action];
                });
            }
        }
    };

    $('body').on('click', '.moderation .action', function (e) {
        e.preventDefault();
        var method = ($(this).data('method')) ? $(this).data('method') : 'POST';
        doModerationAction($(this).data('action'), $(this).data('id'), method, []);
    });
    $('body').on('click', '.upload-image', function(e) {
        e.preventDefault();
        console.log('your mom clicked here');
        $(this).closest('.file-upload').find('input.sr-only').trigger('click');
    });
});