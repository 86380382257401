/*
$(document).ready(function() {
    var cookie = 'ds-page-load';
    var nag_cookie = 'ds-newsletter-nag';

    if ( firstImpression() ) {

        //DROP COOKIE
        Cookies.set(cookie, 1);

        //OPEN LOGIN MODAL FOR FIRST TIMERS
        $('a.open-modal').trigger('click');

    }else{
        //COUNT PAGE LOAD
        count = Cookies.get(cookie);

        // INCREMENT
        count++;

        // SET
        Cookies.set(cookie,count);

        // BOTTOM NAG BAR
        if(count % 5 === 0 &&  App.User.LoggedIn != true )
        {
            $("#nagBar").show();
        }

        // NEWSLETTER NAG
        nagCookie = Cookies.get(nag_cookie);

        if(nagCookie != 1 && App.User.LoggedIn) {
            if (count % 5 === 0 && App.User.HasNewsletters == false) {
                $url = '/ajax/nag-newsletter/';

                $('#theModal').addClass('login-reg_modal').foundation('reveal', 'open', $url);
            }
        }
    }

    // CLOSE FOR DONT ASK and NO THANKS
    $(document).on('click', ".no-thanks", function () {

        $('input:checkbox.advanced-check').prop('checked', false);
        $("#email-updates").prop('checked', false);

        $form = $(this).data('form');
        $post = $(this).data('post');

        $.ajax({
            url: $post,
            data: $("#" + $form).serialize(),
            type: "POST",
            success: function (data, NULL, jqXHR) {

                // DROP COOKIE TO PREVENT NAG
                Cookies.set(nag_cookie, 1, { expires: 366 });

                if (jqXHR.status === 200) {//redirect if  authenticated user.
                    if (data.url != "none") { //Close the Modal
                        $('a.close-reveal-modal').trigger('click');
                    }
                }
            }
        });
    });

});
*/