$(document).on('submit', "#postForm", function (e) {
    e.preventDefault();

    $url = $(this).attr('action');
    $form = $(this).closest('form');
    $ck = $(this).data('ck');

    var data = $form.serializeArray();

    if($ck){
        var ck = CKEDITOR.instances.editor.getData();
        data.push({'name':'content',value:ck});
    }

    $.ajax({
        url: $url,
        data: data,
        type: "POST",
        success: function(data) {

            if(data.flag){
                msg = data.message;
                html = "";
                for (var key in msg) {
                    html  += msg[key] + "<br />";
                    $("#"+key).addClass('event-msg has-error');
                }

                $("#error-msg").html(html);
                $(".errors").show();
            }else{
                //SUCCESS Redirect the User
                window.location.replace(data.url)
            }

        },
        error: function(data) {

        }
    });
});
$(document).on('click', ".delete-post", function (e) {

    e.preventDefault();

    var self = $(this);
    $url = self.data('url');

    $.ajax({
        url: $url,
        data: {_token: CSRF()},
        type: "POST",
        success: function () {
            $(self).closest('tr').remove();
        },
        error: function(error){}
    });


});