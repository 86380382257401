$(document).ready(function(){


    $('.btn.connect:not(.follow, .connected)').on('click',function(e){
        e.preventDefault();

        var baseUrl = '/';
        var settings = {
            user_id: $(this).data('uid'),
            _token: $("meta[name=_token]").attr('content'),
        };

        var connected = function(self) {
            return function(json){
                console.log(json);
                if(json.status == 'success') {
                    console.log(self);
                    $(self).removeClass('btn-blue').addClass('btn-green').find('span').text('Requested');
                }
            };
        };

        $.ajax({
            method: 'post',
            url: baseUrl+'profiles/connect',
            data: settings,
        }).done(connected(this)).fail(function(d){
            var m = $('#theModal');
            m.html("<h1 class='blue-header'>NOTE</h1><p>" + d.responseJSON.data.message + "</p><a class='close-reveal-modal'>&times;</a>");
            m.foundation('reveal', 'open');
            m.addClass('login-reg_modal');
        });
    });
	
	$('.btn.follow').on('click',function(e){
        e.preventDefault();

        var baseUrl = '/';
        var settings = {
            eid: $(this).data('eid'),
            _token: $("meta[name=_token]").attr('content'),
        };

        var connected = function(self) {
            return function(json){
                console.log(json);
                if(json.status == 'success') {
                    console.log(self);
                    $(self).removeClass('btn-blue').addClass('btn-green').find('span').text('Following');
                }
            };
        };

        $.ajax({
            method: 'post',
            url: baseUrl+'profiles/follow',
            data: settings,
        }).done(connected(this)).fail(function(d){
            var m = $('#theModal');
            m.html("<h1 class='blue-header'>NOTE</h1><p>" + d.responseJSON.data.message + "</p><a class='close-reveal-modal'>&times;</a>");
            m.foundation('reveal', 'open');
            m.addClass('login-reg_modal');
        });
    });

    $(document).on('click','.remove-connection-cancel', function(){
        $('a.close-reveal-modal').trigger('click');
    });
    $(document).on('click','.remove-connection', function(e){

        e.preventDefault();

        $this = $(this);
        $post = $this.data('post');
        $.ajax({
            url: $post,
            data: { _token : CSRF()},
            type: "POST",
            success: function (d) {
                window.location.replace(d.data.url);
                $('a.close-reveal-modal').trigger('click');
            },
            error: function (d){
                var m = $('#theModal');
                m.html("<h1 class='blue-header'>NOTE</h1><p>" + d.data.msg + "</p><a class='close-reveal-modal'>&times;</a>");
                m.foundation('reveal', 'open');
            }
        });

    });

});


$(document).foundation();


var primaryActiveNavClass = 'nav-community';
var secondaryActiveNavId = '';

if(primaryActiveNavClass.length > 0){
    $('.has-dd.dd-active').removeClass('dd-active');
    $('.'+primaryActiveNavClass).addClass('dd-active');
}

if(secondaryActiveNavId.length > 0){
    $('.tertiary-nav > li > a').removeClass('dd-active');
    $('#'+secondaryActiveNavId).addClass('dd-active');
}

$(document).foundation();
$('.open-modal').click(function (e) {
    e.preventDefault();
});
$('.disabled').click(function (e) {
    e.preventDefault();
});
$('.has-dd').click(function (e) {
    $('.has-dd.dd-active').not(this).removeClass('dd-active');
    $(this).addClass('dd-active');
});

$("a[href=#menuExpand]").click(function(e) {
    e.preventDefault();
    $(".secondary-nav").toggleClass("menu-opened");
});
$(document).click(function (e) {
    var clicked = $(e.target).parents();
    var higgins = $(clicked).parents().is(".secondary-nav");
    var eliza = $(clicked).parents().is(".menu-icon");
    if($(".secondary-nav").hasClass("menu-opened")) {
        if(higgins == false && eliza == false){
            $(".secondary-nav").toggleClass("menu-opened");
        }
    }
});

// Javascript specifically for the mobile breakpoint
if (window.matchMedia("(max-width: 767px)").matches) {
    $('.has-dd').click(function (e) {
        e.preventDefault();
    });
}