/**
 * Content View JS
 * Pulls content view for use in various buckets, with support for all CView filters.
 */

/**
 * Convert the selected container into a content view bucket.
 */
$.fn.cview = function (action, options) {
    var action = (typeof action == 'undefined') ? 'init' : action;
    var options = (typeof options == 'undefined') ? {} : options;

    var $this = $(this);

    if (action == 'init') {
        var defaults = {
            'filters': {        // List of filters for this bucket, including content types, category restrictions, etc
                'types': [],
                'parent_types': [],
                'cat_include': [],
                'cat_exclude': [],
                'groups': [],
                'limit': [0,7],
                'before': 0,
            },
            'paginate': true,   // Turn 'View More' pagination links on/off
            'preloaded': true,  // Generally, content view is preloaded during page generation. We'll autoload otherwise
        };

        // Build the final options object
        var options = $.extend(true, {}, defaults, $(this).data('options'), options);

        console.log(options);

        // Save options to container
        $(this).data('options', options);

        // Connect 'View More' button
        $this.find(".cview-pagination").click(function(e) {
            e.preventDefault();
            $this.cview('more');
        });
    } else if (action == 'more') {
        // Load settings
        var settings = $this.data('options');

        $this.find(".cview-pagination").html("Loading...");

        // Retrieve content items
        var baseUrl = '/';
        settings._token = $("meta[name=_token]").attr('content');
        $.post(baseUrl+'cview', settings, function(json){

            if(json.data.html.length) {
                $this.find(".cview-pagination").html("LOAD MORE");
            } else {
                $this.find(".cview-pagination").html("No more results").addClass('disabled');
            }

            $(json.data.html).insertBefore($this.find(".load-more")).imagesLoaded().then(function(){
                // Reflow masonry
                $('.grid').masonry();
            });

            // Update pagination and re-save settings
            settings.filters.before = json.data.before;
            $this.data('options', settings);
        }, "json");
    }

    return this;
};

$(document).ready(function(){
    $('.cview-container').each(function(){
        $(this).cview('init');
    });
});