/**
 * Global App variable
 */
var App = {
    'User': {
        'LoggedIn': false,
        'HasNewsletters' : false
    },
    'showLoadingScreen':function(){
        $('#theModal').html("<h1>Loading...</h1>").foundation('reveal','open');
    },
    'loadModal':function(url, target, done) {
        $.get(url, function (data) {
            var m = $('#theModal');
            m.html(data);
            m.foundation('reveal', 'open');
        }).done(done);
    },
    'ajaxError':function (d) {
        if(typeof d != 'object') {
            d = $.parseJSON(d);
        }
        var m = $('#theModal');
        m.html("<h1 class='blue-header'>Sorry, an error occurred</h1><p>" + d.responseJSON.data.message + "</p><a class='close-reveal-modal'>&times;</a>");
        m.foundation('reveal', 'open');
    },
    'Modals': {
        'Advertise':{
            selector: ".modal-action[data-modal='withus']",
            callback: function(target) {
                $('#theModal').addClass('withus-company_modal');
                App.loadModal('/communication/with-us', target);
            }
        },
        'Login':{
            selector: ".modal-action[data-modal='login']",
            callback: function(target) {
                $('#theModal').addClass('login-reg_modal');
                App.loadModal('/auth/login', target);
            }
        },
        'Claim':{
            selector: ".modal-action[data-modal='claim-company']",
            callback: function(target) {
                $('#theModal').addClass('claim-company_modal');
                App.loadModal('/vendor-ratings/claim-company', target);
            }
        },
        'Contact':{
            selector: ".modal-action[data-modal='contact']",
            callback: function(target) {
                $('#theModal').addClass('contact-company_modal');
                App.loadModal('/communication/contact-us', target);
            }
        },
        'Register':{
            selector: ".modal-action[data-modal='register']",
            callback: function(target) {
                $('#theModal').addClass('login-reg_modal');
                App.loadModal('/auth/register', target);
            }
        },
        'Rating': {
            selector: ".modal-action[data-modal='rating']",
            callback: function(target) {
                if (!App.User.LoggedIn) {
                    $('#login').trigger('click');
                    return;
                }

                $('#theModal').addClass('vrModal');

                // Check for Company selection
                var companyId = $(target).data('company-id');

                // Check for Product selection
                var productId = $(target).data('product-id');

                App.loadModal('/submit-review', target, function() {
                    Rate.start(companyId,productId);
                });
            }
        },
        'Product': {
            selector: ".modal-action[data-modal='product']",
            callback: function(target) {
                if (!App.User.LoggedIn) {
                    $('#login').trigger('click');
                    return;
                }

                $('#theModal').addClass('vrModal');
                var categoryId = $(target).data('category-id');
                var companyId = $(target).data('company-id');

                App.loadModal('/submit-review', target, function() {
                    Rate.startProduct(categoryId,companyId);
                });
            }
        },
        'Company': {
            selector: ".modal-action[data-modal='company']",
            callback: function(target) {
                if (!App.User.LoggedIn) {
                    $('#login').trigger('click');
                    return;
                }

                $('#theModal').addClass('vrModal');

                App.loadModal('/submit-review', target, function() {
                    Rate.startCompany();
                });
            }
        },
        'Topic':{
            selector: ".modal-action[data-modal='add-topic']",
            callback: function(target) {
                if (!App.User.LoggedIn) {
                    $('#login').trigger('click');
                    return;
                }

                App.showLoadingScreen();

                // Launch modal
                var data = {'id': $(target).data('id'), '_token': $("meta[name=_token]").attr('content')};

                // Check for a cview container; we'll select the department by name instead of id
                var cview = $(target).closest('div').find('.cview-container').attr('data-options');
                if(typeof cview != 'undefined') {
                    var data_options = $.parseJSON(cview);
                    data.id = data_options.filters.cat_include != undefined ? data_options.filters.cat_include[0][2] : '';
                }

                var m = $('#theModal');
                $.ajax({
                    url: '/forums/topics/add',
                    method: 'get',
                    data: data,
                    dataType: 'html',
                    success: function(d){
                        m.html(d);
                        m.find('select[name="id"]').val(data.id);
                        console.log(target);
                        m.foundation('reveal', 'open', target, {css : {
                            open : {
                                'opacity': 0,
                                'visibility': 'visible',
                                'display' : 'block',
                                'max-width': '50%',
                                'height': 'auto',
                            }
                        }});
                    },
                    error:function (d) {
                        d = $.parseJSON(d);
                        m.html("<h1 class='blue-header'>Sorry, an error occurred</h1><p>" + d.responseJSON.data.message + "</p><a class='close-reveal-modal'>&times;</a>");
                        m.foundation('reveal', 'open');
                    }
                });
            }
        },
        'Conversation':{
            selector: ".modal-action[data-modal='add-conversation']",
            callback: function(target) {
                if (!App.User.LoggedIn) {
                    $('#login').trigger('click');
                    return;
                }

                // Launch modal
                var data = {'id': $(target).data('id'), '_token': $("meta[name=_token]").attr('content')};
                $.ajax({
                    url: $(target).attr('href'),
                    method: 'get',
                    data: data,
                    dataType: 'html'
                }).done(function (d) {
                    $('#theModal').html(d).foundation('reveal', 'open', {css : {
                        open : {
                            'opacity': 0,
                            'visibility': 'visible',
                            'display' : 'block',
                            'max-width': '50%',
                            'height': 'auto',
                        }
                    }});
                }).fail(function (d) {
                    d = $.parseJSON(d);
                    var m = $('#theModal');
                    m.html("<h1 class='blue-header'>Sorry, an error occurred</h1><p>" + d.responseJSON.data.message + "</p><a class='close-reveal-modal'>&times;</a>");
                    m.foundation('reveal', 'open');
                });
            }
        },
        'Cropper':{
            selector: ".modal-action[data-modal='cropper']",
            callback: function(target) {

                $('#cropper-modal').data('crop-settings', target);
                $('#cropper-modal .instructions').html($(target).data('instructions'));
                $('#cropper-modal').foundation('reveal', 'open');
            }
        },
        'Likes' :{
            selector: ".modal-action[data-modal='likes']",
            callback: function(target) {
                id = $(target).data('id');
                $('#theModal').addClass('login-reg_modal');
                App.loadModal('/content/likes/'+id, target);
            }

        },
        'Remove-Relationship':{
            selector: ".modal-action[data-modal='remove-relationship']",
            callback: function(target) {

                if (!App.User.LoggedIn) {
                    $('#login').trigger('click');
                    return;
                }

                //App.showLoadingScreen();
                $url = $(target).data('url');

                var data = {'id': $(target).data('id'),
                            'title': $(target).data('title'),
                            'body': $(target).data('body'),
                            'post': $(target).data('post'),
                            '_token': $("meta[name=_token]").attr('content')
                            };
                $.ajax({
                    url: $url,
                    method: 'get',
                    data: data,
                    dataType: 'html'
                }).done(function (d) {
                    $('#theModal').html(d).foundation('reveal', 'open', {css : {
                        open : {
                            'opacity': 0,
                            'visibility': 'visible',
                            'display' : 'block',
                            'height': 'auto',
                        }
                    }
                });
                }).fail(function (d) {
                    d = $.parseJSON(d);
                    var m = $('#theModal');
                    m.html("<h1 class='blue-header'>Sorry, an error occurred</h1><p>" + d.responseJSON.data.message + "</p><a class='close-reveal-modal'>&times;</a>");
                    m.foundation('reveal', 'open');
                });

            }
        }
    }
};

function CSRF() {
    return $("meta[name=_token]").attr('content');
}

function Loader(btn) {
    $(btn).addClass('sending');
    $(btn).data('loader-original', $(btn).html());
    $(btn).html("");
}

function LoaderDone(btn) {
    $(btn).html($(btn).data('loader-original'));
    $(btn).removeData('loader-original');
    $(btn).removeClass('sending');
}

function MinMaxDisplay() {
    $('.minMaxDisplay').each(function(){
        var self = $(this);
        var min_text = 'Req chars: ';
        var max_text = 'Chars remaining: ';
        var wrap = self.parent().append('<div class="minMaxWrapper" id="minMaxWrapper_'+self.attr('id')+'"><div class="minMaxOutput '+self.prop('tagName').toLowerCase()+'Type"></div></div>').find('#minMaxWrapper_'+self.attr('id'));
        self.appendTo(wrap);
        var min = parseInt(self.attr('data-minlength'));
        var max = parseInt(self.attr('data-maxlength'));
        var out = wrap.find('.minMaxOutput');
        if(min > 0){
            out.html(min_text+min);
            out.css('background','rgba(253,238,141,1)');
        } else if(max > 0){
            out.html(max_text+max);
            out.css('background','rgba(206,245,209,1)');
        }
        self.on('change keyup blur', function(){
            var len = $(this).val().length, out_text = '', out_num=0;
            if(min > 0 && len < min) {
                out_text = min_text;
                out_num = min - len;
                out.css('background','rgba(253,238,141,1)');
            } else if(max > 0 && len >= min && len < max){
                out_text = max_text;
                out_num = max-len;
                out.css('background','rgba(206,245,209,1)');
            } else if(max > 0 && len >= max) {
                $(this).val($(this).val().substr(0,max));
                out_text = max_text;
                out_num = 0;
                out.css('background','rgba(247,214,207,1)');
            }
            if(out_text.length){
                out.html(out_text + out_num).show();
            } else {
                out.html('').hide();
            }
        });
        self.trigger('change');
    });
}

$(document).ready(function () {
    App.User.LoggedIn = ( $("meta[name=_logged_in]").attr('content') == 'true' );
    App.User.HasNewsLetters = ( $("meta[name=_has_newsletters]").attr('content') == 'true' );

    // Add CSRF to all requests
    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': CSRF()
        }
    });

    $(document).ajaxComplete(function(){
        $('.btn.sending').each(function(i,btn) {
            LoaderDone(btn);
        });
    });

    $(document).on('click', '.btn.btn-submit.sending', function(e){
        e.stopImmediatePropagation();
    });

    // Hook up button-based modal links
    for(var m in App.Modals) {
        var f = (function(i){
            return function(e){ e.preventDefault();
            $("#theModal").removeClass().addClass('reveal-modal');
            App.Modals[i].callback(this); }
        })(m);
        $('body').on('click', App.Modals[m].selector, f);
    }

    // Handle login request from hash
    if(window.location.hash == '#login') {
        $(App.Modals['Login'].selector).trigger('click');
    }

    // Handle registration request from hash
    if(window.location.hash == '#createprofile') {
        $(App.Modals['Register'].selector).trigger('click');
    }

    // Handle Rate Vendor request from hash
    if(window.location.hash == '#ratevendor' || window.location.hash == '#rate') {
        $(App.Modals['Rating'].selector + '.modal-primary').trigger('click');
    }

    $('a[href^="http://"]').not('a[href*="'+ baseUrl() + '"]')
                            .not('a[href*="hcm."]')
                            .not('a[href*="events."]')
                            .attr('target','_blank')
                            .attr('rel',"noopener noreferrer");
    $('a[href^="https://"]').not('a[href*="'+ baseUrl() + '"]')
                            .not('a[href*="hcm."]')
                            .not('a[href*="events."]')
                            .attr('target','_blank')
                            .attr('rel',"noopener noreferrer");

    $('.pagination-container').on('click', 'a.comments', function(e) {
        e.preventDefault();
        var cid = $(this).data('cid');
        $('.rating-id-'+cid).toggle();
    });
    // Handle pagination links
    $('.pagination-container').on('click', 'a.showMore', function(e) {
        e.preventDefault();
        var self = $(this);
        var limit = 5;
        if(self.data('current') == undefined) {
            var this_data = eval(self.attr('iterator'));
            self.data('current', this_data[0]*1+1);
            self.data('count', this_data[1]);
            self.data('content', self.closest('.commentSection').find('.pagination-content'));
            self.data('num', self.closest('.commentSection').find('.number-of-comments'));
        }
        self.data('content').fadeTo(200, 0.25);
        var url = self.attr('href')+self.data('current')+'/'+limit+'?order=desc';
        $.ajax({
            url: url,
            method: 'get',
            dataType: 'json'
        }).done(function (d) {
            var htm = self.data('current') == 1 ? '' : self.data('content').html();
            self.data('content').html(d.data.body+htm);
            self.data('content').fadeTo(200, 1.0);
            var spread = self.data('current')*limit ;
            self.data('num').html((spread < self.data('count') ? spread : self.data('count'))+' of '+self.data('count')+' comments');
            if(self.data('current') * limit < self.data('count')) {
                self.data('current', self.data('current')+1);
            } else {
                self.hide();
            }
        }).fail(App.ajaxError);
    });
    $('.pagination-container').on('click', '.pagination a', function(e) {
        e.preventDefault();

        var paginator = $(this).closest('.pagination-container').find('.pagination-controls');
        var content = $(this).closest('.pagination-container').find('.pagination-content');

        // Visually indicate something is happening
        paginator.find('.current').removeClass('current');
        $('.filter-container').removeClass('filter-sponsored');
        $(this).addClass('current');
        content.fadeTo(200, 0.25);
        paginator.fadeTo(200, 0.5);

        $('html, body').animate({
            scrollTop: $(content).offset().top
        }, 300);


        var url = $(this).attr('href');
        if($('.date-filters input.date_start').val()){
            url += "?date_start="+$('.date-filters input.date_start').val();
            url += "&date_end="+$('.date-filters input.date_end').val();
        }
        $.ajax({
            url: url,
            method: 'get',
            dataType: 'json'
        }).done(function (d) {

            content.html(d.data.body);
            content.fadeTo(200, 1.0);

            paginator.replaceWith(d.data.paginator);
            paginator.fadeTo(200, 1.0);

            impressions();

        }).fail(App.ajaxError);
    });

    // Handle new comment request from hash
    if(window.location.hash == '#new') {
        $('.pagination-container a.pageClick:last').trigger('click');
    }

    // Handle tour
    if(App.User.LoggedIn && firstImpression('welcome')) {
        $('#welcomeOne').foundation('reveal', 'open');
    }

    $('table.responsive').wrap('<div class="table-responsive-wrapper" />');
    MinMaxDisplay();


    $('.date-range-datepicker').datepicker({
        dateFormat: "yy-mm-dd"
    });
    $('.date-range-datepicker-load').on('click', function(){
        $(this).closest('.filter-container').submit();
    });
});

function dateRangePrefill(target, ds, de){
    $(target+'.date_start').val(ds);
    $(target+'.date_end').val(de);
    $(target+'.date_end').closest('.filter-container').find('.date-range-datepicker-load').click();
    return false;
}

function baseUrl()
{
    pathArray = location.href.split( '/' );
    protocol = pathArray[0];
    host = pathArray[2];
    url = protocol + '//' + host;

    return url;
}

// Fn to allow an event to fire after all images are loaded
// http://stackoverflow.com/a/17989377
$.fn.imagesLoaded = function () {
    // get all the images (excluding those with no src attribute)
    var $imgs = this.find('img[src!=""]');
    // if there's no images, just return an already resolved promise
    if (!$imgs.length) {return $.Deferred().resolve().promise();}

    // for each image, add a deferred object to the array which resolves when the image is loaded (or if loading fails)
    var dfds = [];
    $imgs.each(function(){

        var dfd = $.Deferred();
        dfds.push(dfd);
        var img = new Image();
        img.onload = function(){dfd.resolve();}
        img.onerror = function(){dfd.resolve();}
        img.src = this.src;

    });

    // return a master promise object which will resolve when all the deferred objects have resolved
    // IE - when all the images are loaded
    return $.when.apply($,dfds);
}

$.fn.selectRange = function(start, end) {
    var e = $(this)[0];
    if (!e) return;
    else if (e.setSelectionRange) { e.focus(); e.setSelectionRange(start, end); } /* WebKit */
    else if (e.createTextRange) { var range = e.createTextRange(); range.collapse(true); range.moveEnd('character', end); range.moveStart('character', start); range.select(); } /* IE */
    else if (e.selectionStart) { e.selectionStart = start; e.selectionEnd = end; }
};

$(document).on('submit', 'form.addSimpleCommentForm', function(e){
    e.preventDefault();
    if(!App.User.LoggedIn) {
        $(App.Modals['Login'].selector).trigger('click');
        return false;
    }
    var addCommentForm = $(this);
    var data = addCommentForm.serializeArray();
    var addComment = addCommentForm.find('input[name="addComment"]');
    var err = addCommentForm.find('.error');
    var ck = addComment.val();
    data.push({'name':'content',value:ck});

    if(ck.length == 0){
        err.find('span').html('Could not save empty comment.');
        err.removeClass('success').addClass('alert');
        err.show();
    } else {
        Loader($(this).find('.btn.btn-submit'));
        $.ajax({
            url: addCommentForm.attr('action'),
            method: 'POST',
            data: data,
            dataType: 'json'
        }).done(function(d){
            err.hide();
            if(typeof successCallback !== 'undefined'){
                successCallback(d);
            }
            addCommentForm.trigger('reset');
            err.find('span').html(d.data.message);
            err.removeClass('alert').addClass('success');
            err.show();
            $(document).foundation('alert', 'reflow');

            // Add comment to current list
            if(typeof d.data.comment != 'undefined') {
                var comment = $(d.data.comment).hide();
                addCommentForm.closest('.commentSection').find('.pagination-content .post-comment').last().after(comment);
                addCommentForm.closest('.commentSection').find('.pagination-content .post-comment').last().show('fast');
            }

            // Clear editor
            addComment.val('');

            if(typeof d.data.redirect != 'undefined') {
                window.location = d.data.redirect;
            }
        }).fail(function(d){
            err.find('span').html(d.responseJSON.data.message);
            err.removeClass('success').addClass('alert');
            err.show();
        });
    }
});

/* / Inline editors
$('.commentSection').on('click', '.post-comment .flag-comment .js-flag-edit', function(e){
    e.preventDefault();
    var content_id = $(this).closest('.post-comment').data('content-id');
    var id = $(this).data('edit-id');
    var el = $('#'+id);
    el.attr('contenteditable', 'true');
    el.on( 'blur', function( e ) {
        var content = editor.getData();
        el.attr('contenteditable', 'false');
        el.html("<div class='alert-box radius' style='position: relative'>Saving</div>");
        // Save
        var data = {
            'id': content_id,
            'content': content,
            'reason': null,
        };
        $.ajax({
            url: '/comments/edit',
            method: 'POST',
            data: data,
            dataType: 'json'
        }).done(function(d){
            el.html(d.data.body);
        }).fail(function(d){
            App.ajaxError(d);
            el.html("Error");
        });
    } );
});
*/
