// url with custom callbacks

$('a.open-modal').on('click', function () {
    $url = $(this).data('url');

    $('#theModal').foundation('reveal', 'open', $url);
});

$(document).on('submit', '.register-form', function(e) {
    e.preventDefault();
    $(this).find('.register-modal').trigger('click');
});

$(document).on('click', ".register-back", function () {

    $this = $(this);

    // URL to go back to.
    $backurl = $this.data('url');

    // GET HTML for the Modal
    $.get($backurl, function (html) {
        $('#theModal').html(html);
    });

});


$(document).on('click', ".register-modal", function () {

    $nexturl = $(this).data('url');
    $form = $(this).data('form');
    $post = $(this).data('post');

    var id = 0;
    var next = false;

    $.ajax({
        url: $post,
        data: $("#" + $form).serialize(),
        type: "POST",
        success: function (data, NULL, jqXHR) {
            if (jqXHR.status === 200) {//redirect if  authenticated user.
                id = data.id;
                next = true;
                $url = $nexturl;
                if (data.url != "none") { //Close the Modal and GoTo Redirect Path from url

                    $('a.close-reveal-modal').trigger('click');

                    if(data.url != "close") { // REDIRECT USER IF ITS NOT JUST A CLOSE MODAL
                        window.location.replace(data.url);
                    }
                }else {
                    $.get($url, function (html) {
                        $('#theModal').html(html);
                    });
                }
            }
            else if (jqXHR.status === 201) {// Not Authenticated User

                d = data.messages;
                html = "";
                for (var key in data.messages) {
                    if (data.messages.hasOwnProperty(key)) {
                        var obj = data.messages[key];
                        for (var prop in obj) {
                            // important check that this is objects own property
                            // not from prototype prop inherited
                            if (obj.hasOwnProperty(prop)) {
                                html += obj[prop] + ' <br />';
                            }
                        }
                    }
                }
                $("#error-msg").html(html);
                $(".errors").show();
            }
            else {
                console.log(data);
            }
        },
        error: function (data) {
            d = data.messages;
            for (var i = 0; i < d.length; i++) {
                var obj = d[i];
            }

        }
    });
});

$(document).on('submit', "#loginForm", function (e) {
    e.preventDefault();

    $nexturl = $(this).data('url');
    $form = $("#loginForm");

    formData = $form.serializeArray();
    formData.push({'name':'redirect','value':window.location});

    $.ajax({
        url: '/ajax/login/',
        data: formData,
        type: "POST",
        success: function (data, NULL, jqXHR) {
            if (jqXHR.status === 200) {//redirect if  authenticated user.
                $('a.close-reveal-modal').trigger('click');
                window.location.replace(data.url);
                location.reload();
            }
            else if (jqXHR.status === 201) {// Not Authenticated User
                d = data.messages;
                html = "";
                for (var key in data.messages) {
                    if (data.messages.hasOwnProperty(key)) {
                        var obj = data.messages[key];
                        for (var prop in obj) {
                            // important check that this is objects own property
                            // not from prototype prop inherited
                            if (obj.hasOwnProperty(prop)) {

                                html += obj[prop] + ' <br />';
                            }
                        }
                    }
                }
                $("#error-msg").html(html);
                $(".errors").show();
            }
            else if (jqXHR.status === 298) {// Not Authenticated User
                msg = jqXHR.responseJSON.msg;
                if (!Array.isArray(msg)) {
                    msg = [msg];
                }
                html = "";
                for (var key in msg) {
                    html += msg[key] + "<br />";
                }
                $("#error-msg").html(html);
                $(".errors").show();
            }
        },
        error: function (data) {
            msg = data.responseJSON;

            if (!Array.isArray(msg)) {
                msg = [msg];
            }
            html = "";
            for (var key in msg) {
                html += msg[key] + "<br />";
            }

            $("#error-msg").html(html);
            $(".errors").show();
        }
    });
});

$(document).on('click', ".close-register-modal", function (e) {

    e.preventDefault();
    var cancel = $(this).data('current');

    $.get('/registration/stop/?u='+cancel, function (data) {
        $('#theModal').html(data);
    });

});

$(document).on('click', ".close-modal", function () {

    $refreshUrl = $(this).data('url');

    $('a.close-reveal-modal').trigger('click');
    window.location.replace($refreshUrl)
});

$(document).on('click', ".cancel-return", function (e) {

    e.preventDefault();
    var url = $(this).data('url');
    $.get(url, function (data) {
        $('#theModal').html(data);
    });

});

$(document).on('click', ".confirm-close", function () {

    var pathname = window.location.href;

    $('a.close-reveal-modal').trigger('click');
    window.location.replace(pathname);
});


$(document).on('click', ".facebook-login", function () {

    $nexturl = $(this).data('url');

    $.get($nexturl, function (data) {
        $('#theModal').html(data);
    });
});

$(document).on('click', ".password-reset", function () {
    $.get($(this).data('url'), function (data) {
        $('#theModal').html(data);
    });
});

// Close button jquery override of foundation
$(document).on('click', ".errors a.close", function(){
    $(this).parents('.errors').hide();
});

$(document).ready(function(){
    if(window.location.hash == '#finish-registration') {
        $("#theModal").addClass('login-reg_modal');
        App.loadModal('/auth/finish-registration');
    }
});